import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DisableSidebar} from '../../_metronic/layout/core'
import {WithChildren} from '../../_metronic/helpers'
import {Registration} from '../modules/auth/components/Registration_staff'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const PermissionsPage = lazy(
    () => import('../modules/apps/setting/access/permissions/PermissionPage')
  )
  const RolesPage = lazy(() => import('../modules/apps/setting/access/roles/RolePage'))
  const UsersPage = lazy(() => import('../modules/apps/setting/access/user/UsersPage'))
  const StaffPage = lazy(() => import('../modules/apps/setting/staff/staff/StaffPage'))
  const IzinCutiPageStaff = lazy(
    () => import('../modules/apps/setting/izin-cuti/staff/IzinCutiPage')
  )
  const IzinCutiPageAdmin = lazy(
    () => import('../modules/apps/setting/izin-cuti/admin/IzinCutiPage')
  )
  const PermintaanTalentPage = lazy(
    () => import('../modules/apps/setting/career/permintaan-talent/UsersPage')
  )
  const ScreeningPage = lazy(() => import('../modules/apps/setting/career/screening/UsersPage'))

  const ActiveShiftingPage = lazy(
    () => import('../modules/apps/setting/shifting/active-shifting/UsersPage')
  )
  const ShiftingSchedulePage = lazy(
    () => import('../modules/apps/setting/shifting/shift-schedules/UsersPage')
  )
  const ProgramPage = lazy(() => import('../modules/apps/setting/programs/programs/UsersPage'))
  const PeriodePage = lazy(() => import('../modules/apps/setting/programs/periode/UsersPage'))
  const RunningPeriodedanProgramPage = lazy(
    () => import('../modules/apps/setting/programs/running-periode-and-program/UsersPage')
  )
  const LevelPage = lazy(() => import('../modules/apps/setting/programs/level/Page'))

  const OmnichannelCsPage = lazy(() => import('../modules/apps/setting/omnichanel/cs/Page'))
  const OmnichannelBroadcastPage = lazy(
    () => import('../modules/apps/setting/omnichanel/broadcast/Page')
  )

  const MitraPage = lazy(() => import('../modules/apps/setting/teacher/mitra/MitraPage'))
  const TeacherStaffPage = lazy(() => import('../modules/apps/setting/teacher/staff/TeacherPage'))

  const LeadsPage = lazy(() => import('../modules/apps/customer/leads/LeadsPage'))
  const MembersPage = lazy(() => import('../modules/apps/customer/members/MembersPage'))

  const TransactionsPage = lazy(
    () => import('../modules/apps/transaction/transaction/TransactionPage')
  )
  const ToeflSimulationPage = lazy(
    () => import('../modules/apps/course/toefl/toefl/ToeflPredictionPage')
  )
  const ResultToeflSimulationPage = lazy(
    () => import('../modules/apps/course/toefl/toefl-result/Page')
  )
  const PlacementTestSimulationPage = lazy(
    () => import('../modules/apps/course/placement-test/placement-test/PlacementTestPage')
  )
  const ResultPlacementTestSimulationPage = lazy(
    () => import('../modules/apps/course/placement-test/placement-test-result/Page')
  )
  const ClassExamPage = lazy(
    () => import('../modules/apps/course/class-exam/class-exam/ClassExamPage')
  )

  const ResultClassExamPage = lazy(
    () => import('../modules/apps/course/class-exam/class-exam-result/Page')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='registration-staff-jago-bahasa' element={<Registration />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Route Setting */}
        <Route
          path='/setting/access/permissions/*'
          element={
            <SuspensedView>
              <PermissionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/setting/access/roles/*'
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />
        <Route
          path='/setting/access/users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='setting/staff/*'
          element={
            <SuspensedView>
              <StaffPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/setting/staff/add-staff'
          element={
            <SuspensedView>
              <AddStaffPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/setting/izin-cuti/staff/*'
          element={
            <SuspensedView>
              <IzinCutiPageStaff />
            </SuspensedView>
          }
        />
        <Route
          path='/setting/izin-cuti/admin/*'
          element={
            <SuspensedView>
              <IzinCutiPageAdmin />
            </SuspensedView>
          }
        />
        <Route
          path='setting/career/recruitment-application/*'
          element={
            <SuspensedView>
              <PermintaanTalentPage />
            </SuspensedView>
          }
        />
        <Route
          path='setting/career/applicants/*'
          element={
            <SuspensedView>
              <ScreeningPage />
            </SuspensedView>
          }
        />
        <Route
          path='setting/teacher/mitra/*'
          element={
            <SuspensedView>
              <MitraPage />
            </SuspensedView>
          }
        />
        <Route
          path='setting/teacher/staff/*'
          element={
            <SuspensedView>
              <TeacherStaffPage />
            </SuspensedView>
          }
        />
        <Route
          path='setting/shifting/shifting-active/*'
          element={
            <SuspensedView>
              <ActiveShiftingPage />
            </SuspensedView>
          }
        />
        <Route
          path='setting/shifting/shift-schedules/*'
          element={
            <SuspensedView>
              <ShiftingSchedulePage />
            </SuspensedView>
          }
        />
        <Route
          path='setting/programs/programs/*'
          element={
            <SuspensedView>
              <ProgramPage />
            </SuspensedView>
          }
        />
        <Route
          path='setting/programs/periode/*'
          element={
            <SuspensedView>
              <PeriodePage />
            </SuspensedView>
          }
        />
        <Route
          path='setting/programs/running-periode-and-program/*'
          element={
            <SuspensedView>
              <RunningPeriodedanProgramPage />
            </SuspensedView>
          }
        />
        <Route
          path='setting/programs/levels/*'
          element={
            <SuspensedView>
              <LevelPage />
            </SuspensedView>
          }
        />
        <Route
          path='setting/omnichannel/cs*'
          element={
            <SuspensedView>
              <OmnichannelCsPage />
            </SuspensedView>
          }
        />
        <Route
          path='setting/omnichannel/broadcast*'
          element={
            <SuspensedView>
              <OmnichannelBroadcastPage />
            </SuspensedView>
          }
        />

        {/* Route Customers */}
        <Route
          path='customers/leads/*'
          element={
            <SuspensedView>
              <LeadsPage />
            </SuspensedView>
          }
        />
        <Route
          path='customers/members/*'
          element={
            <SuspensedView>
              <MembersPage />
            </SuspensedView>
          }
        />

        {/* Route Transactions */}
        <Route
          path='transactions/*'
          element={
            <SuspensedView>
              <TransactionsPage />
            </SuspensedView>
          }
        />

        {/* Route Course*/}
        <Route
          path='course/toefl-simulation/soal-toefl/*'
          element={
            <SuspensedView>
              <ToeflSimulationPage />
            </SuspensedView>
          }
        />

        <Route
          path='course/toefl-simulation/result-toefl/*'
          element={
            <SuspensedView>
              <ResultToeflSimulationPage />
            </SuspensedView>
          }
        />

        <Route
          path='course/placement-test-simulation/soal-placement-test/*'
          element={
            <SuspensedView>
              <PlacementTestSimulationPage />
            </SuspensedView>
          }
        />

        <Route
          path='course/placement-test-simulation/result-placement-test/*'
          element={
            <SuspensedView>
              <ResultPlacementTestSimulationPage />
            </SuspensedView>
          }
        />

        <Route
          path='course/class-exam/questions/*'
          element={
            <SuspensedView>
              <ClassExamPage />
            </SuspensedView>
          }
        />

        <Route
          path='course/class-exam/results/*'
          element={
            <SuspensedView>
              <ResultClassExamPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {PrivateRoutes}
