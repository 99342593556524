/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'
import {KTIcon} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {checkPermission} from './../../../../abstracts/helper/globalJs'

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      {checkPermission(currentUser, 'dashboard') && (
        <AsideMenuItem
          to='/dashboard'
          icon='bi bi-speedometer text-info'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      )}

      {checkPermission(currentUser, 'setting') && (
        <AsideMenuItemWithSub
          to='/setting'
          title='Setting'
          fontIcon='bi-archive'
          icon='bi bi-sliders text-info'
        >
          {checkPermission(currentUser, 'setting.access') && (
            <AsideMenuItemWithSub to='/setting/access' title='Access' hasBullet={true}>
              {checkPermission(currentUser, 'setting.access.permissions') && (
                <AsideMenuItem
                  to='/setting/access/permissions'
                  title='Permissions'
                  hasBullet={true}
                />
              )}
              {checkPermission(currentUser, 'setting.access.roles') && (
                <AsideMenuItem to='/setting/access/roles' title='Roles' hasBullet={true} />
              )}
              {checkPermission(currentUser, 'setting.access.users') && (
                <AsideMenuItem to='/setting/access/users' title='Users' hasBullet={true} />
              )}
            </AsideMenuItemWithSub>
          )}
          {checkPermission(currentUser, 'setting.staff') && (
            <AsideMenuItem to='/setting/staff' title='Staff' hasBullet={true} />
          )}
          {checkPermission(currentUser, 'setting.teachers') && (
            <AsideMenuItemWithSub to='/setting/teacher' title='Teachers' hasBullet={true}>
              {checkPermission(currentUser, 'setting.teachers.teacher_staff') && (
                <AsideMenuItem to='/setting/teacher/staff' title='Teacher Staff' hasBullet={true} />
              )}
              {checkPermission(currentUser, 'setting.teachers.teacher_mitra') && (
                <AsideMenuItem to='/setting/teacher/mitra' title='Teacher Mitra' hasBullet={true} />
              )}
            </AsideMenuItemWithSub>
          )}
          {checkPermission(currentUser, 'setting.members') && (
            <AsideMenuItemWithSub to='/setting/members' title='Members' hasBullet={true}>
              {checkPermission(currentUser, 'setting.members.dataClosing') && (
                <AsideMenuItem
                  to='/setting/members/data-closing'
                  title='Data Closing'
                  hasBullet={true}
                />
              )}

              {checkPermission(currentUser, 'setting.members.retention') && (
                <AsideMenuItem
                  to='/setting/members/retention'
                  title='Data Retention'
                  hasBullet={true}
                />
              )}
            </AsideMenuItemWithSub>
          )}
          {checkPermission(currentUser, 'setting.careers') && (
            <AsideMenuItemWithSub to='/setting/career' title='Careers' hasBullet={true}>
              {checkPermission(currentUser, 'setting.careers.recruitment_application') && (
                <AsideMenuItem
                  to='/setting/career/recruitment-application'
                  title='Recruitment Application'
                  hasBullet={true}
                />
              )}
              {checkPermission(currentUser, 'setting.careers.applicants') && (
                <AsideMenuItem
                  to='/setting/career/applicants'
                  title='Applicants'
                  hasBullet={true}
                />
              )}
            </AsideMenuItemWithSub>
          )}
          {checkPermission(currentUser, 'setting.izin_cuti') && (
            <AsideMenuItemWithSub to='/setting/izin-cuti' title='Izin/Cuti' hasBullet={true}>
              {checkPermission(currentUser, 'setting.izin_cuti.staff') && (
                <AsideMenuItem to='/setting/izin-cuti/staff' title='Staff' hasBullet={true} />
              )}
              {checkPermission(currentUser, 'setting.izin_cuti.admin') && (
                <AsideMenuItem to='/setting/izin-cuti/admin' title='Admin' hasBullet={true} />
              )}
            </AsideMenuItemWithSub>
          )}
          {checkPermission(currentUser, 'setting.programs') && (
            <AsideMenuItemWithSub to='/setting/programs' title='Programs' hasBullet={true}>
              {checkPermission(currentUser, 'setting.programs.programs') && (
                <AsideMenuItem to='/setting/programs/programs' title='Programs' hasBullet={true} />
              )}
              {checkPermission(currentUser, 'setting.programs.levels') && (
                <AsideMenuItem to='/setting/programs/levels' title='Levels' hasBullet={true} />
              )}
              {checkPermission(currentUser, 'setting.programs.periodes') && (
                <AsideMenuItem to='/setting/programs/periode' title='Periodes' hasBullet={true} />
              )}
              {checkPermission(currentUser, 'setting.programs.running_period') && (
                <AsideMenuItem
                  to='/setting/programs/running-periode-and-program'
                  title='Running Period & Program'
                  hasBullet={true}
                />
              )}
              {checkPermission(currentUser, 'setting.programs.vouchers') && (
                <AsideMenuItem to='/setting/programs/vouchers' title='Vouchers' hasBullet={true} />
              )}
            </AsideMenuItemWithSub>
          )}
          {checkPermission(currentUser, 'setting.omnichannels') && (
            <AsideMenuItemWithSub to='/setting/omnichannel' title='Omnichannel' hasBullet={true}>
              {checkPermission(currentUser, 'setting.omnichannels.cs') && (
                <AsideMenuItem to='/setting/omnichannel/cs' title='Cs' hasBullet={true} />
              )}

              {checkPermission(currentUser, 'setting.omnichannels.broadcast') && (
                <AsideMenuItem
                  to='/setting/omnichannel/broadcast'
                  title='Broadcast'
                  hasBullet={true}
                />
              )}
            </AsideMenuItemWithSub>
          )}
          {checkPermission(currentUser, 'setting.shifting') && (
            <AsideMenuItemWithSub to='/setting/shifting' title='Shifting' hasBullet={true}>
              {checkPermission(currentUser, 'setting.shifting.shifting_active') && (
                <AsideMenuItem
                  to='/setting/shifting/shifting-active/index'
                  title='Active Shifting'
                  hasBullet={true}
                />
              )}
              {checkPermission(currentUser, 'setting.shifting.shift_schedules') && (
                <AsideMenuItem
                  to='/setting/shifting/shift-schedules'
                  title='Shift Schedules'
                  hasBullet={true}
                />
              )}
            </AsideMenuItemWithSub>
          )}
        </AsideMenuItemWithSub>
      )}

      {checkPermission(currentUser, 'transactions') && (
        <AsideMenuItem
          to='/transactions'
          title='Transactions'
          fontIcon='bi-archive'
          icon='bi bi-credit-card text-info'
        />
      )}

      {checkPermission(currentUser, 'customers') && (
        <AsideMenuItemWithSub
          to='/customers'
          title='Customers'
          fontIcon='bi-archive'
          icon='bi bi-person-circle text-info'
        >
          {checkPermission(currentUser, 'customers.leads') && (
            <AsideMenuItem to='/customers/leads' title='Leads' hasBullet={true} />
          )}
          {checkPermission(currentUser, 'customers.members') && (
            <AsideMenuItem to='/customers/members' title='Members' hasBullet={true} />
          )}
          {checkPermission(currentUser, 'customers.labelling') && (
            <AsideMenuItem to='/customers/labelling' title='Labelling' hasBullet={true} />
          )}
        </AsideMenuItemWithSub>
      )}

      {checkPermission(currentUser, 'courses') && (
        <AsideMenuItemWithSub
          to='/course'
          title='Courses'
          fontIcon='bi-archive'
          icon='bi bi-book text-info'
        >
          {/* {checkPermission(currentUser, 'courses.toefl') && (
            <AsideMenuItem to='/course/toefl-simulation' title='Toefl' hasBullet={true} />
          )} */}

          {checkPermission(currentUser, 'courses.toefl') && (
            <AsideMenuItemWithSub to='/course/toefl-simulation' title='Toefl' hasBullet={true}>
              {checkPermission(currentUser, 'courses.toefl.soal_toefl') && (
                <AsideMenuItem
                  to='/course/toefl-simulation/soal-toefl'
                  title='Soal Toefl'
                  hasBullet={true}
                />
              )}
              {checkPermission(currentUser, 'courses.toefl.result_toefl') && (
                <AsideMenuItem
                  to='/course/toefl-simulation/result-toefl'
                  title='Result toefl'
                  hasBullet={true}
                />
              )}
            </AsideMenuItemWithSub>
          )}

          {checkPermission(currentUser, 'courses.placement_test') && (
            <AsideMenuItemWithSub
              to='/course/placement-test-simulation'
              title='Placement Test'
              hasBullet={true}
            >
              {checkPermission(currentUser, 'courses.placement_test.soal_placement_test') && (
                <AsideMenuItem
                  to='/course/placement-test-simulation/soal-placement-test'
                  title='Soal Placement Test'
                  hasBullet={true}
                />
              )}
              {checkPermission(currentUser, 'courses.placement_test.result_placement_test') && (
                <AsideMenuItem
                  to='/course/placement-test-simulation/result-placement-test'
                  title='Result Placement Test'
                  hasBullet={true}
                />
              )}
            </AsideMenuItemWithSub>
          )}

          {/* {checkPermission(currentUser, 'courses.class_exam') && (
            <AsideMenuItem to='/course/class-exam' title='Class Exam' hasBullet={true} />
          )} */}

          {checkPermission(currentUser, 'courses.class_exam') && (
            <AsideMenuItemWithSub to='/course/class-exam' title='Class Exam' hasBullet={true}>
              {checkPermission(currentUser, 'courses.class_exam.questions') && (
                <AsideMenuItem
                  to='/course/class-exam/questions'
                  title='Questions'
                  hasBullet={true}
                />
              )}
              {checkPermission(currentUser, 'courses.class_exam.results') && (
                <AsideMenuItem
                  to='/course/class-exam/results'
                  title='Results'
                  hasBullet={true}
                />
              )}
            </AsideMenuItemWithSub>
          )}
        </AsideMenuItemWithSub>
      )}

      {checkPermission(currentUser, 'schedules') && (
        <AsideMenuItemWithSub
          to='/schedule'
          title='Schedules'
          fontIcon='bi-archive'
          icon='bi bi-calendar3 text-info'
        >
          {checkPermission(currentUser, 'schedules.class') && (
            <AsideMenuItemWithSub to='/schedule/class' title='Class' hasBullet={true}>
              {checkPermission(currentUser, 'schedules.class.regular') && (
                <AsideMenuItem to='/schedule/class/Regular' title='Regular' hasBullet={true} />
              )}
              {checkPermission(currentUser, 'schedules.class.private') && (
                <AsideMenuItem to='/schedule/class/private' title='Private' hasBullet={true} />
              )}
            </AsideMenuItemWithSub>
          )}
          {checkPermission(currentUser, 'schedules.absence') && (
            <AsideMenuItem to='/schedule/absence' title='Absence' hasBullet={true} />
          )}
        </AsideMenuItemWithSub>
      )}

      {checkPermission(currentUser, 'grades') && (
        <AsideMenuItemWithSub
          to='/grade'
          title='Grades'
          fontIcon='bi-archive'
          icon='bi bi-award text-info'
        >
          {checkPermission(currentUser, 'grades.grades') && (
            <AsideMenuItem to='/grade/grade' title='Grades' hasBullet={true} />
          )}
          {checkPermission(currentUser, 'grades.certificates') && (
            <AsideMenuItem to='/grade/certificate' title='Certificates' hasBullet={true} />
          )}
        </AsideMenuItemWithSub>
      )}

      {/* {checkPermission(currentUser, 'blast') && (
        <AsideMenuItem
          to='/blasting'
          title='Blast'
          fontIcon='bi-archive'
          icon='bi bi-broadcast text-info'
        />
      )} */}

      {checkPermission(currentUser, 'customer_feedback') && (
        <AsideMenuItemWithSub
          to='/customer-feedback'
          title="Customer's Feedback"
          fontIcon='bi-archive'
          icon='bi bi-chat-square-heart text-info'
        >
          {checkPermission(currentUser, 'customer_feedback.complain') && (
            <AsideMenuItem to='/customer-feedback/complain' title='Complain' hasBullet={true} />
          )}
          {checkPermission(currentUser, 'customer_feedback.feedback') && (
            <AsideMenuItem to='/customer-feedback/feedback' title='Feedback' hasBullet={true} />
          )}
          {checkPermission(currentUser, 'customer_feedback.teacher_rate') && (
            <AsideMenuItem
              to='/customer-feedback/teacher-rate'
              title='Teacher Rates'
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      )}

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a target='_blank' className='menu-link' href='#'>
          <span className='menu-icon'>
            <KTIcon iconName='document' className='fs-2' />
          </span>
          <span className='menu-title'>Documentation</span>
        </a>
      </div>
    </>
  )
}
